import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private breakpoints = {
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width: 959px)',
    large: '(min-width: 960px) and (max-width: 1279px)',
    xlarge: '(min-width: 1280px)',
  };

  constructor(private breakpointObserver: BreakpointObserver) {}

  isSmallScreen(): Observable<boolean> {
    return this.breakpointObserver
      .observe(this.breakpoints.small)
      .pipe(map((state: BreakpointState) => state.matches));
  }

  isMediumScreen(): Observable<boolean> {
    return this.breakpointObserver
      .observe(this.breakpoints.medium)
      .pipe(map((state: BreakpointState) => state.matches));
  }

  isLargeScreen(): Observable<boolean> {
    return this.breakpointObserver
      .observe(this.breakpoints.large)
      .pipe(map((state: BreakpointState) => state.matches));
  }

  isXLargeScreen(): Observable<boolean> {
    return this.breakpointObserver
      .observe(this.breakpoints.xlarge)
      .pipe(map((state: BreakpointState) => state.matches));
  }

  getScreenSize(): Observable<string> {
    return this.breakpointObserver
      .observe(Object.values(this.breakpoints))
      .pipe(
        map((state: BreakpointState) => {
          if (state.breakpoints[this.breakpoints.small]) {
            return 'small';
          } else if (state.breakpoints[this.breakpoints.medium]) {
            return 'medium';
          } else if (state.breakpoints[this.breakpoints.large]) {
            return 'large';
          } else if (state.breakpoints[this.breakpoints.xlarge]) {
            return 'xlarge';
          }
          return 'unknown';
        })
      );
  }
}
